import imageInput from '@/mixins/image-input.js';
import GamesPostModel from "../games/models/games-post.model";

export default {
    data() {
        return {
            item: {}
        }
    },
    methods: {
        approve() {
            let data = new GamesPostModel(this.item);

            let formData = new FormData();
            for (let key in data) {
                formData.append(key, data[key]);
            }

            this.$store.dispatch("games/updateItem", {id: this.id, data: formData});
        }
    },
    created() {
        this.$store.dispatch("games/getItemById", this.id).then((res) => {
            this.item = res;
        });
    },
    computed: {
        id() {
            return this.$route.params.id;
        },
    },
    mixins: [imageInput]
}
